import {useEffect, useRef, useState} from 'react';
import Title from 'antd/es/typography/Title';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  ConfigProvider,
  DatePicker,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from 'antd';
import {useHistory} from 'react-router-dom';
import {
  Course,
  CoursesConnection,
  Level,
  Package,
  Status,
  Student,
  StudentsConnection,
  Test,
  TestTypes,
  VideosessionInsert,
} from '../../graphql/API';
import {StudentsService} from '../Students';
import {PackagesService} from '../Package';
import {LevelsService} from '../Level';
import {TestsService} from '../Test';
import {SmileOutlined} from '@ant-design/icons';
import {CoursesService} from '../Courses';

const coursesService = new CoursesService();
const testsService = new TestsService();

export const VideoSessionCreate = () => {
  const history = useHistory();
  const {Title, Text} = Typography;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [courses, setCourses] = useState<Course[]>([]);
  const Timeout = useRef<any>();

  const [videoSession, setVideoSession] = useState<VideosessionInsert>({
    levelId: '',
    courseId: '',
    testId: '',
    curriculumId: '',
    duration: 0,
    name: '',
    isActive: false,
  });

  const [tests, setTests] = useState<Array<Test> | []>([]);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    coursesService
      .getCoursesWithoutPlan()
      .then((resp) => {
        console.log('course', resp);
        setIsLoading(false);
        setCourses(resp);
      })
      .catch((error) => {
        alert('Fetching course error');
        console.log(error);
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Create Video Session </Title>
      <>
        <Row>
          <Col span={10}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Course
              </Text>

              <Select
                showSearch
                style={{width: '100%'}}
                placeholder="Select a course"
                optionFilterProp="children"
                onChange={(courseId) => {
                  const findCourse: Course = courses.find(
                    (curse: Course) => curse.id === courseId,
                  );

                  console.log('LSSSS', findCourse);

                  setVideoSession({
                    ...videoSession,
                    curriculumId: findCourse.curriculum.id,
                    levelId: findCourse.level.id,
                    courseId: findCourse.id,
                  });
                }}>
                {courses?.map((curse: Course) => (
                  <Select.Option value={curse?.id!}>{curse.name}</Select.Option>
                ))}
              </Select>
            </div>
          </Col>

          <Col span={10}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Test
              </Text>
              <Select
                showSearch
                style={{width: '100%'}}
                placeholder="Select a test"
                optionFilterProp="children"
                onSearch={(searchValue) => {
                  if (searchValue.length > 3) {
                    testsService
                      .getTestByName(searchValue)
                      .then((testsResult) => {
                        setTests(testsResult);
                      });
                  }
                }}
                onChange={(testId: string) => {
                  console.log('testId', testId);
                  const findTest = tests.find(
                    (test: Test) => test.id === testId,
                  );
                  setVideoSession({
                    ...videoSession,
                    testId,
                    name: findTest?.name,
                  });
                }}>
                {tests?.map((tst) => {
                  if (tst.type === TestTypes.STORY) {
                    return (
                      <Select.Option value={tst.id!}>{tst.name}</Select.Option>
                    );
                  }
                })}
              </Select>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Session Name
              </Text>
              <Input
                placeholder="Enter Session name"
                value={videoSession.name}
                maxLength={25}
                onChange={(event) => {
                  setVideoSession({
                    ...videoSession,
                    name: event?.target?.value,
                  });
                }}
              />
            </div>
          </Col>

          <Col span={10}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                Duration in minutes
              </Text>
              <Input
                placeholder="Enter duration"
                value={videoSession.duration}
                maxLength={3}
                onChange={(event) => {
                  setVideoSession({
                    ...videoSession,
                    duration: parseFloat(
                      event?.target?.value ? event?.target?.value : '0',
                    ),
                  });
                }}
              />
            </div>
          </Col>
        </Row>
      </>
      <Col>
        <div style={{margin: 20}}>
          <Checkbox
            checked={videoSession?.isActive}
            onChange={(event) => {
              setVideoSession({
                ...videoSession,
                isActive: event.target.checked,
              });
            }}>
            Active
          </Checkbox>
        </div>
      </Col>
      <Button
        onClick={async () => {
          if (
            videoSession.courseId &&
            videoSession.levelId &&
            videoSession.testId &&
            videoSession.duration &&
            videoSession.curriculumId &&
            videoSession.name
          ) {
            console.log('Video session', videoSession);
            try {
              setIsLoading(true);
              await coursesService.createVideoSession(videoSession);
              setIsLoading(false);

              alert('Created Successfuly');

              history.goBack();
            } catch (error) {
              setIsLoading(false);

              alert('Failed to create video session' + JSON.stringify(error));
            }
          }
        }}>
        Create
      </Button>

      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
    </div>
  );
};
