import {useEffect, useState} from 'react';
import {useParams, Link, useHistory} from 'react-router-dom';
import {Button, Select, Spin, Typography, Collapse} from 'antd';

import {Plan, Test} from '../../graphql/API';
import {PlansService} from './PlansService';
import {TestsService} from '../Test';

const plansService = new PlansService();
const testsService = new TestsService();
export const PlanEdit = () => {
  const history = useHistory();

  const [testsSearchResult, setTestsSearchResult] = useState<Array<Test>>();
  const [allFetchedTests, setAllFetchedTests] = useState<Array<Test>>([]);
  const [plan, setPlan] = useState<Plan | undefined>(undefined);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let params: {planId: string} = useParams();

  const {Panel} = Collapse;
  const {Title} = Typography;

  useEffect(() => {
    plansService
      .getPlanById(params.planId)
      .then((resp) => {
        setIsLoading(false);
        console.log('resp->', resp);
        setPlan(resp);
      })
      .catch((error) => {
        alert('Fetching plans error');
        //console.log(error);
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  const renderDay = (plan: Plan) => {
    return Array.from({length: 365}, (_, dayObject) => dayObject + 1).map(
      (dayObject) => {
        return (
          <Panel header={`d${dayObject}`} key={dayObject}>
            <Select
              autoClearSearchValue={false}
              mode="multiple"
              style={{width: '100%'}}
              placeholder="Please select"
              //@ts-ignore
              defaultValue={plan[`d${dayObject}`]?.map((m) => {
                return m?.name + '+' + m?.id!;
              })}
              onSearch={(searchValue) => {
                if (searchValue.length > 3) {
                  testsService
                    .getTestByName(searchValue)
                    .then((testsResult) => {
                      setTestsSearchResult(testsResult);
                      let tempTests = allFetchedTests;
                      // @ts-ignore
                      tempTests = tempTests.concat(testsResult);
                      let list = [
                        ...new Map(
                          tempTests.map((item) => [item.id, item]),
                        ).values(),
                      ];

                      setAllFetchedTests(list);
                    });
                }
              }}
              onChange={(idWithNameList) => {
                // Extract test IDs from the selected values
                const testIds = idWithNameList?.map(
                  (e: string) => e.split('+')[1],
                );

                // Get currently selected tests that are still selected
                const currentSelectedTests =
                  plan[`d${dayObject}`]?.filter((test) =>
                    testIds.includes(test.id),
                  ) || [];

                // Get newly selected tests from all fetched tests
                const newlySelectedTests = allFetchedTests.filter((test) =>
                  testIds.includes(test.id),
                );

                // Combine tests and remove duplicates using a Map
                // This is more efficient than the filter approach
                const uniqueTests = Array.from(
                  new Map(
                    [...currentSelectedTests, ...newlySelectedTests].map(
                      (test) => [test.id, test],
                    ),
                  ).values(),
                );

                // Create a proper copy of the plan to avoid mutation issues
                const updatedPlan = {...plan};

                // Update the specific day with the unique tests
                updatedPlan[`d${dayObject}`] = uniqueTests;

                // Update the plan state with the new object
                setPlan(updatedPlan);
              }}>
              {/* @ts-ignore */}
              {testsSearchResult?.map((k, index) => (
                <Select.Option key={k.id || index} value={k.name + '+' + k.id!}>
                  <p>{k.name}</p>
                </Select.Option>
              ))}
            </Select>
          </Panel>
        );
      },
    );
  };

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Plan Edit</Title>
      {!isError && !isLoading && (
        <>
          <Collapse defaultActiveKey={'d1'}>{plan && renderDay(plan)}</Collapse>
        </>
      )}
      <div style={{width: '100%', textAlign: 'center', marginTop: '20px'}}>
        <Button
          style={{}}
          size={'large'}
          onClick={async () => {
            let planObj = plan;
            let planId = plan?.id;
            let updatedAt = plan?.updatedAt;

            delete planObj?.id;
            delete planObj?.createdAt;
            delete planObj?.updatedAt;
            //@ts-ignore
            delete planObj?.entity;

            let newPlan = {};
            //console.log('plan', plan);
            // @ts-ignore
            Object.entries(plan)?.map((dayObject) => {
              let key = dayObject[0];
              let value = dayObject[1];
              if (typeof value === 'object') {
                //console.log('VVVVVV', typeof value);
                //@ts-ignore
                let ids = value?.map((v) => v.id);
                //@ts-ignore
                newPlan[key] = ids;
              } else if (key.startsWith('d')) {
                //@ts-ignore
                newPlan[key] = [];
              }
            });
            let extraPlan = {...newPlan};
            // //console.log('PLAMN', newPlan);
            // if (Object.keys(newPlan).length < 120) {
            //   for (
            //     let index = Object.keys(newPlan).length;
            //     index <= 120;
            //     index++
            //   ) {
            //     extraPlan['d' + index] = [];
            //   }
            // }
            // //console.log(JSON.stringify(extraPlan));
            await plansService
              //@ts-ignore
              .updatePlan(planId!, updatedAt!, JSON.stringify(extraPlan))
              .then((e) => {
                alert('Plan edited');
                history.push('/plans/' + planId);
              })
              .catch((er) => {
                console.log('updating plan error ->', er);
                alert('Plan edit failed');
              });
          }}>
          Save
        </Button>
      </div>
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Plan fetching error ⚠️</Title>}
    </div>
  );
};
