import {useEffect, useState} from 'react';
import {useParams, Link, useHistory} from 'react-router-dom';
import {Button, Select, Spin, Typography, Collapse, Row, Col} from 'antd';

import {Course, Test, Videosession} from '../../graphql/API';
import {TestsService} from '../Test';
import {CoursesService} from './CoursesService';

const testsService = new TestsService();
const coursesService = new CoursesService();

export const CourseEdit = () => {
  const history = useHistory();

  const [tests, setTests] = useState<Array<Test>>();
  const [course, setCourse] = useState<Course | undefined>(undefined);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let params: {courseId: string} = useParams();

  const {Panel} = Collapse;
  const {Title, Text} = Typography;

  useEffect(() => {
    coursesService
      .getCourse(params.courseId)
      .then((resp) => {
        console.log('course', course);
        setIsLoading(false);
        setCourse(resp);
      })
      .catch((error) => {
        alert('Fetching course error');
        console.log(error);
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  const renderVideoSessions = () => {
    return course?.videosessions?.map((videoSession: Videosession) => {
      return (
        <Panel header={videoSession?.test?.name} key={videoSession?.test?.id}>
          <Select
            mode="multiple"
            style={{width: '100%'}}
            placeholder="Please select"
            // defaultValue={value?.map((m) => {
            //   return m?.name + '+' + m?.id!;
            // })}
            onSearch={(searchValue) => {
              if (searchValue.length > 3) {
                testsService.getTestByName(searchValue).then((testsResult) => {
                  setTests(testsResult);
                });
              }
            }}
            onChange={(idWithNameList) => {
              // console.log('idWithNameList->', idWithNameList);
              // let tempPlan = course;
              // let testIds = idWithNameList?.map((e: any) => e.split('+')[1]);
              // //console.log('testIds->', testIds);
              // let selectedTests = tests?.filter((t) =>
              //   testIds.includes(t.id),
              // );
              // //console.log('selectedTests->', selectedTests);
              // //@ts-ignore
              // tempPlan[key] = selectedTests;
              // //@ts-ignore
              // setPlan(tempPlan);
              /*let tempUpdatedFields = updatedFields;
                //@ts-ignore
                tempUpdatedFields[key] = testIds;
                setUpdatedFields(tempUpdatedFields);*/
            }}>
            {tests?.map((k) => (
              <Select.Option value={k.name + '+' + k.id!}>
                <p>{k.name}</p>
              </Select.Option>
            ))}
          </Select>
        </Panel>
      );
    });
  };

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Course Edit</Title>
      {!isError && !isLoading && (
        <>
          <Collapse defaultActiveKey={'d1'}>
            {course && renderVideoSessions()}
          </Collapse>
        </>
      )}

      {course && (
        <Row>
          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                course: {course.id}
              </Text>
            </div>
          </Col>

          <Col span={12}>
            <div style={{margin: 20}}>
              <Text style={{display: 'block'}} strong={true}>
                course: {course.id}
              </Text>
            </div>
          </Col>
        </Row>
      )}
      {course?.videosessions?.length > 0 && (
        <div style={{width: '100%', textAlign: 'center', marginTop: '20px'}}>
          <Button style={{}} size={'large'} onClick={async () => {}}>
            Save
          </Button>
        </div>
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Course fetching error ⚠️</Title>}
    </div>
  );
};
