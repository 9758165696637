import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
  Button,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tabs,
  Typography,
  Upload,
  UploadProps,
  Checkbox,
} from 'antd';

import {CreateAssetBlock} from '../../components';
import {
  Asset,
  AssetType,
  ProgramType,
  Question,
  QUESTION_TYPE,
  Test,
  TestTypes,
} from '../../graphql/API';
import {TestsService} from './TestsService';
import {validateCreateTest} from '../../utils/Validators';
import {CloseCircleFilled, UploadOutlined} from '@ant-design/icons';
import {StorageService, UploadFileType} from '../Lesson/StorageService';
import {UploadFile} from 'antd/lib/upload/interface';
import {QUESTION_TYPE_DICT} from './QuestionTypeDict';

const testService = new TestsService();
const storageService = new StorageService();

export const TestEdit = () => {
  const history = useHistory();
  let params: {testId: string} = useParams();

  const [fileAudio, setFileAudio] = useState<UploadFile>();

  const [uploading, setUploading] = useState(false);
  function cleanStringForAI(input) {
    return input
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9'\s]/g, ''); // Remove special characters and punctuation, except apostrophe
  }
  const {Title, Text} = Typography;
  const {TabPane} = Tabs;
  const emptyTestPane = {
    name: '',
    //@ts-ignore
    correctAsset: {
      type: AssetType.AUDIO,
      thumbnailUri: '',
      fileUri: '',
    },
    //@ts-ignore
    asset: {
      type: AssetType.PHOTO,
      thumbnailUri: '',
      fileUri: '',
    },
    programType: ProgramType.LANGUAGE,
    summary: 'Record with your own voice',
    description:
      'Press the photo or the words to listen to the pronunciation and then press the record button to record it with your own voice.',
    text: '',
    textAI: '',
    type: QUESTION_TYPE.READ_SENTENCE,
    choicesCount: [],
    correctIndex: {},
    originalTextAI: '',
  };

  //@ts-ignore
  const [panes, setPanes] = useState<Array<Question>>([emptyTestPane]);
  const [activeKey, setActiveKey] = useState<any>(0);
  const [selectedtest, setSelectedTest] = useState<Test>();
  const [fileSummaryVideo, setFileSummaryVideo] = useState<UploadFile>();

  const [testName, setTestName] = useState<string>('');
  const [testType, setTestType] = useState<TestTypes | undefined>(
    TestTypes.QUIZ,
  );
  // @ts-ignore
  const [testAsset, setTestAsset] = useState<Asset | undefined>({
    type: AssetType.VIDEO,
    thumbnailUri: '',
    fileUri: '',
  });
  const [testDescription, setTestDescription] = useState<string>(
    'Watch the video at least 2 times and after that record the questions’ answers.',
  );
  const [testSummary, setTestSummary] = useState<string>(
    'Watch carefully at least 2 times and answer the questions.',
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectChoice, setSelectChoice] = useState<string>('');

  const onChange = (activeKey: string) => {
    setActiveKey(parseInt(activeKey));
    if (panes[activeKey].type === QUESTION_TYPE.COMPREHENSION) {
      const firstKey = Object.keys(panes[activeKey].customChoices)[0];
      setSelectChoice(firstKey);
    }
    setFileSummaryVideo(null);
  };

  const onEdit = (targetKey: any, action: any) => {
    //this[action](targetKey);
    switch (action) {
      case 'add':
        add();
        break;
      default:
        console.log('CASE DEFAULT');
    }
  };

  useEffect(() => {
    testService
      .getTestById(params.testId)
      .then((tst: Test) => {
        // Transform questions if necessary
        const updatedQuestions = tst.questions.map((qst: Question) => {
          if (qst.type === QUESTION_TYPE.COMPREHENSION) {
            // Step 1: Clean up duplicates of CHOICES placeholders
            const removeDuplicateChoices = (text: string) => {
              const choiceRegex = /\b(CHOICES\d+)\b/g; // Matches CHOICES followed by a number
              const seenChoices = new Set<string>();

              return text
                .replace(choiceRegex, (match) => {
                  if (seenChoices.has(match)) {
                    return ''; // Remove duplicate placeholder
                  } else {
                    seenChoices.add(match);
                    return match; // Keep the first occurrence
                  }
                })
                .replace(/\s+/g, ' ')
                .trim(); // Clean up extra spaces
            };
            const clearText = removeDuplicateChoices(qst.text);
            // Step 2: Update the text with cleaned placeholders
            // qst.text = clearText;
            // qst.textAI = clearText;
            //@ts-ignore
            qst.originalTextAI = clearText;
            // Parse customChoices if it's a string
            qst.customChoices =
              typeof qst.customChoices === 'string'
                ? JSON.parse(qst.customChoices)
                : qst.customChoices || {};

            // Parse choicesCount if it's a string
            //@ts-ignore
            qst.choicesCount = //@ts-ignore
              typeof qst.choicesCount === 'string' //@ts-ignore
                ? JSON.parse(qst.choicesCount) //@ts-ignore
                : qst.choicesCount || {};

            // Parse correctIndex if it's a string
            //@ts-ignore
            qst.correctIndex = //@ts-ignore
              typeof qst.correctIndex === 'string' //@ts-ignore
                ? JSON.parse(qst.correctIndex) //@ts-ignore
                : qst.correctIndex || {};

            // Ensure we have at least 2 choices for each choice option
            Object.keys(qst.customChoices).forEach((choiceKey) => {
              if (!Array.isArray(qst.customChoices[choiceKey])) {
                qst.customChoices[choiceKey] = ['', ''];
              }
              while (qst.customChoices[choiceKey].length < 2) {
                qst.customChoices[choiceKey].push('');
              }
            });

            // Auto-detect correct answers if not already set
            if (qst.textAI && qst.text) {
              const originalText = qst.text;
              const answerText = qst.textAI;

              // Create a map to track which choices have been detected
              const detectedCorrectChoices = {};

              Object.keys(qst.customChoices).forEach((choiceKey) => {
                // Find this choice placeholder in the original text
                if (originalText.includes(choiceKey)) {
                  // If we already have a correctIndex for this choice, use it
                  if (
                    //@ts-ignore
                    qst.correctIndex && //@ts-ignore
                    qst.correctIndex[choiceKey] !== undefined
                  ) {
                    detectedCorrectChoices[choiceKey] = //@ts-ignore
                      qst.correctIndex[choiceKey];
                    return;
                  }

                  // Try to determine which choice is used in the answer text
                  const choices = qst.customChoices[choiceKey];

                  // First, try exact matches
                  for (let i = 0; i < choices.length; i++) {
                    const choice = choices[i];
                    if (
                      choice &&
                      choice.trim() &&
                      answerText.includes(choice)
                    ) {
                      // This choice appears to be the correct one
                      detectedCorrectChoices[choiceKey] = i;
                      break;
                    }
                  }

                  // If no match found, try more flexible matching
                  if (detectedCorrectChoices[choiceKey] === undefined) {
                    // Replace the choiceKey in the original text with each choice and see if it matches the answer text
                    for (let i = 0; i < choices.length; i++) {
                      const choice = choices[i];
                      if (choice && choice.trim()) {
                        // Create a test text with this choice substituted
                        const testText = originalText.replace(
                          new RegExp(choiceKey, 'g'),
                          choice,
                        );
                        if (
                          answerText.includes(testText) ||
                          testText.includes(answerText)
                        ) {
                          detectedCorrectChoices[choiceKey] = i;
                          break;
                        }
                      }
                    }
                  }
                }
              });

              // Update the correctIndex with our detected choices
              if (Object.keys(detectedCorrectChoices).length > 0) {
                //@ts-ignore
                if (!qst.correctIndex) {
                  //@ts-ignore
                  qst.correctIndex = {};
                }

                // Merge the detected choices with the existing correctIndex
                //@ts-ignore
                Object.assign(qst.correctIndex, detectedCorrectChoices);
              }
            }
          }
          // debugger;
          return qst;
        });

        setSelectedTest(tst);
        setTestName(tst.name);
        setTestType(tst.type);
        setPanes([...updatedQuestions]); // Set panes to transformed questions
        setTestDescription(tst.description);
        setTestSummary(tst.summary);
        setTestAsset(tst.asset);
        setActiveKey(0);

        // Initialize selectChoice if comprehension question exists
        const firstComprehension = updatedQuestions.find(
          (q) => q.type === QUESTION_TYPE.COMPREHENSION,
        );
        if (firstComprehension) {
          const firstKey = Object.keys(firstComprehension.customChoices)[0];
          setSelectChoice(firstKey);

          // Update textAI for all comprehension questions to ensure it reflects the correct choices
          updatedQuestions.forEach((question) => {
            if (
              question.type === QUESTION_TYPE.COMPREHENSION &&
              //@ts-ignore
              question.correctIndex
            ) {
              // Start with the original text
              //@ts-ignore
              let aiText = question.originalTextAI || question.text || '';

              // Replace each choice placeholder with the selected choice
              //@ts-ignore
              Object.keys(question.correctIndex).forEach((choiceKey) => {
                //@ts-ignore
                const correctIndex = question.correctIndex[choiceKey];
                const selectedValue =
                  question.customChoices[choiceKey]?.[correctIndex] ||
                  choiceKey;

                // Replace the choice key with the selected value
                aiText = aiText.replace(
                  new RegExp(choiceKey, 'g'),
                  selectedValue,
                );
              });

              // Update the textAI
              question.textAI = aiText;
            }
          });
        }

        setIsLoading(false);
      })
      .catch((error: any) => {
        console.error('Error fetching test:', error);
        setIsLoading(false);
      });
  }, [params.testId]);

  const add = () => {
    setSelectChoice('');
    const activeKey = panes.length;
    const newPanes = panes;
    // @ts-ignore
    newPanes.push(emptyTestPane);
    setPanes(newPanes);
    setActiveKey(activeKey);
  };

  const propsAudioUpload: UploadProps = {
    type: 'select',
    accept: '.aac',
    listType: 'text',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      setFileAudio(null);
    },
    beforeUpload: (file) => {
      setFileAudio(file);

      return false;
    },
  };

  const propsSummaryVideo: UploadProps = {
    type: 'select',
    accept: '.mp4,.aac',
    listType: 'text',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      setFileSummaryVideo(null);
    },
    beforeUpload: (file) => {
      setFileSummaryVideo(file);

      return false;
    },
  };

  const handleUpload = async (
    fileUri: UploadFile,
    typeUpload: UploadFileType,
    index?: number,
  ) => {
    setUploading(true);
    console.log('fileUri.url', fileUri);
    try {
      let filePath: string;
      switch (typeUpload) {
        case UploadFileType.TEST_MEDIA:
          filePath = await storageService.uploadFile(
            fileUri,
            typeUpload,
            'test/media',
          );
          let tempAsset = {
            fileUri: filePath,
            type: AssetType.AUDIO,
            thumbnailUri: '',
          };
          let tempPanes = [...panes];
          // @ts-ignore
          tempPanes[index].correctAsset = tempAsset;
          setPanes(tempPanes);
          break;

        case UploadFileType.TEST_MEDIA_VIDEO_SESSION:
          filePath = await storageService.uploadFile(
            fileUri,
            typeUpload,
            'test/media',
          );
          let tempPaness = [...panes];
          // @ts-ignore
          tempPaness[index].videoAISessionUri = filePath;
          setPanes(tempPaness);
          break;

        default:
          break;
      }

      console.log('file_PATH_IUPLOADED', filePath);
      message.success('upload successfully.');

      setUploading(false);
    } catch (error) {
      console.log('error:', error);
      setUploading(false);
      message.error('upload failed.');
    }
  };

  return (
    <>
      <div
        style={{
          padding: 10,
          width: '100vw',
        }}>
        <Title style={{textAlign: 'center'}}>Edit test </Title>
        <>
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text>Test name </Text>
            <Input
              value={testName}
              onChange={(e) => {
                setTestName(e.target.value);
              }}
              placeholder="Test name"
            />
          </div>
          {/* global question type selections */}
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text style={{display: 'block'}}>Test type </Text>
            <Select
              value={testType}
              showSearch
              style={{width: '100%'}}
              placeholder="Select a test type"
              optionFilterProp="children"
              onChange={(selectedTestType: TestTypes) => {
                setTestType(selectedTestType);
              }}>
              <Select.Option value={TestTypes.QUIZ}>
                {TestTypes.QUIZ}
              </Select.Option>
              <Select.Option value={TestTypes.PLACEMENT_TEST}>
                {TestTypes.PLACEMENT_TEST}
              </Select.Option>
              <Select.Option value={TestTypes.STORY}>
                {TestTypes.STORY}
              </Select.Option>
            </Select>
          </div>
          {/* test description input */}
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text>Test description </Text>
            <Input
              value={testDescription}
              onChange={(e) => {
                setTestDescription(e.target.value);
              }}
              placeholder="Test description"
            />
          </div>
          {/* test summery input */}
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text>Test summary </Text>
            <Input
              value={testSummary}
              onChange={(e) => {
                setTestSummary(e.target.value);
              }}
              placeholder="Test summary"
            />
          </div>

          <CreateAssetBlock
            asset={testAsset}
            defaultAssetType={testAsset?.type ?? null}
            // @ts-ignore TODO be checked
            onChange={(selectedAsset) => {
              console.log('ONCHAAA', selectedAsset);
              setTestAsset(selectedAsset);
            }}
          />
        </>

        <Tabs
          type="editable-card"
          onChange={onChange}
          activeKey={activeKey.toString()}
          onEdit={onEdit}>
          {panes?.map((pane, index) => (
            <TabPane
              tab={'question_' + index}
              key={index}
              closable={false}
              style={{
                border: '2px solid gray',
                padding: '10px',
              }}>
              <Button
                style={{
                  backgroundColor: 'red',
                  color: 'white',
                  fontWeight: 'bold',
                }}
                onClick={() => {
                  if (
                    // eslint-disable-next-line no-restricted-globals
                    confirm(
                      'The question will be removed from the test. \nAre you sure?',
                    )
                  ) {
                    let newPanes = panes.filter((item) => item.id !== pane.id);
                    setPanes(newPanes);
                    setActiveKey(index - 1);
                  }
                }}>
                Remove question from test
              </Button>
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <Text>Question name</Text>
                <Input
                  value={pane?.name}
                  onChange={(e) => {
                    let tempPanes = [...panes];
                    tempPanes[index].name = e.target.value;
                    setPanes(tempPanes);
                  }}
                  placeholder="Question name"
                />
              </div>
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <Text>Question Summary</Text>
                <Input
                  value={panes[index].summary}
                  onChange={(e) => {
                    let tempPanes = [...panes];
                    tempPanes[index].summary = e.target.value;
                    setPanes(tempPanes);
                  }}
                  placeholder="Question Summary"
                />
              </div>
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <Text>Question description</Text>
                <Input
                  value={panes[index].description}
                  onChange={(e) => {
                    let tempPanes = [...panes];
                    tempPanes[index].description = e.target.value;
                    setPanes(tempPanes);
                  }}
                  placeholder="Question description"
                />
              </div>
              {testType === TestTypes.STORY && (
                <div style={{marginTop: '20px', marginBottom: '20px'}}>
                  <Text>Video AI Session url </Text>

                  {panes[index].videoAISessionUri ? (
                    <Row
                      style={{
                        display: 'flex',
                        // justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                      }}>
                      <Input
                        style={{
                          width: '80%',
                        }}
                        value={panes[index].videoAISessionUri}
                        onChange={(e) => {}}
                        placeholder="Basic usage"
                      />
                      {panes[index].videoAISessionUri && (
                        <CloseCircleFilled
                          style={{marginInline: 20}}
                          onClick={() => {
                            let tempPanes = [...panes];
                            tempPanes[index].videoAISessionUri = null;
                            setPanes(tempPanes);
                            setFileSummaryVideo(null);
                          }}
                        />
                      )}
                    </Row>
                  ) : (
                    <Row>
                      <Upload {...propsSummaryVideo}>
                        <Button
                          disabled={fileSummaryVideo ? true : false}
                          icon={<UploadOutlined />}>
                          Select File
                        </Button>
                      </Upload>

                      <Button
                        type="primary"
                        onClick={() => {
                          handleUpload(
                            fileSummaryVideo,
                            UploadFileType.TEST_MEDIA_VIDEO_SESSION,
                            index,
                          );
                        }}
                        disabled={!fileSummaryVideo}
                        loading={uploading}>
                        {uploading ? 'Uploading' : 'Start Upload'}
                      </Button>
                    </Row>
                  )}
                </div>
              )}
              {/* question type option selection */}
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <Text style={{display: 'block'}}>Question type </Text>
                <Select
                  value={panes[index].type}
                  showSearch
                  style={{width: 300}}
                  placeholder="Select a question type"
                  optionFilterProp="children"
                  onChange={(selectedQuestionType: any) => {
                    let tempPanes = [...panes];
                    tempPanes[index].text = '';
                    tempPanes[index].textAI = '';
                    tempPanes[index].choices = [];
                    console.log('selectedQuestionType->', selectedQuestionType);
                    tempPanes[index].type = selectedQuestionType;

                    // Initialize properties for COMPREHENSION type
                    if (selectedQuestionType === QUESTION_TYPE.COMPREHENSION) {
                      // If there's text already, parse it for CHOICES
                      if (tempPanes[index].text) {
                        const choicesOptions = tempPanes[index].text.split(' ');
                        let newOptions = {};
                        const numOfchoiceOptions = choicesOptions.filter(
                          (text) => text.startsWith('CHOICES'),
                        );

                        if (numOfchoiceOptions.length > 0) {
                          numOfchoiceOptions.forEach(
                            (value) => (newOptions[value] = ['', '']), // Initialize with two empty strings
                          );

                          //@ts-ignore
                          tempPanes[index].customChoices = newOptions;

                          //@ts-ignore
                          tempPanes[index].choicesCount = {};

                          //@ts-ignore
                          tempPanes[index].correctIndex = {};

                          numOfchoiceOptions.forEach((value) => {
                            //@ts-ignore
                            tempPanes[index].choicesCount[value] = 2;
                          });

                          // Store original text for correct answer replacement
                          //@ts-ignore
                          tempPanes[index].originalTextAI =
                            tempPanes[index].text;

                          // Set the first choice as active
                          setSelectChoice(numOfchoiceOptions[0]);
                        } else {
                          // No CHOICES found, initialize empty objects
                          //@ts-ignore
                          tempPanes[index].customChoices = {};
                          //@ts-ignore
                          tempPanes[index].choicesCount = {};
                          //@ts-ignore
                          tempPanes[index].correctIndex = {};
                          //@ts-ignore
                          tempPanes[index].originalTextAI =
                            tempPanes[index].text;
                        }
                      } else {
                        // No text yet, initialize empty objects
                        //@ts-ignore
                        tempPanes[index].customChoices = {};
                        //@ts-ignore
                        tempPanes[index].choicesCount = {};
                        //@ts-ignore
                        tempPanes[index].correctIndex = {};
                        //@ts-ignore
                        tempPanes[index].originalTextAI = '';
                      }
                    } else {
                      // For other question types, reset these properties
                      //@ts-ignore
                      tempPanes[index].customChoices = {};
                      //@ts-ignore
                      tempPanes[index].choicesCount = {};
                    }

                    setPanes(tempPanes);
                  }}>
                  <Select.Option value={QUESTION_TYPE.READ_SENTENCE}>
                    {QUESTION_TYPE.READ_SENTENCE} (default)
                  </Select.Option>

                  {/*MUST ENTER 4 Choices*/}
                  <Select.Option value={QUESTION_TYPE.CHOICES}>
                    {QUESTION_TYPE.CHOICES}
                  </Select.Option>

                  {/*MUST ENTER 8 Choices*/}
                  {/* <Select.Option value={QUESTION_TYPE.BUILD_SENTENCE}>
                    {QUESTION_TYPE.BUILD_SENTENCE}
                  </Select.Option> */}

                  {/*MUST ENTER TEXTAI*/}
                  <Select.Option value={QUESTION_TYPE.MISSING_LETTERS}>
                    {QUESTION_TYPE.MISSING_LETTERS}
                  </Select.Option>
                  <Select.Option value={QUESTION_TYPE.MISSING_WORD}>
                    {QUESTION_TYPE.MISSING_WORD}
                  </Select.Option>
                  {/*MUST ENTER 4 Choices*/}
                  <Select.Option value={QUESTION_TYPE.COMPREHENSION}>
                    {QUESTION_TYPE.COMPREHENSION}
                  </Select.Option>
                  {/* TRANSCRIBE_AND_SCORE */}
                  <Select.Option value={QUESTION_TYPE.TRANSCRIBE_AND_SCORE}>
                    {QUESTION_TYPE.TRANSCRIBE_AND_SCORE}
                  </Select.Option>
                  {/* DESCRIBE_IMAGE_ONLY_PRONUNCIATION */}
                  <Select.Option
                    value={QUESTION_TYPE.DESCRIBE_IMAGE_ONLY_PRONUNCIATION}>
                    {QUESTION_TYPE.DESCRIBE_IMAGE_ONLY_PRONUNCIATION}
                  </Select.Option>
                  {/* FREE_TEXT_ONLY_PRONUNCIATION */}
                  <Select.Option
                    value={QUESTION_TYPE.FREE_TEXT_ONLY_PRONUNCIATION}>
                    {QUESTION_TYPE.FREE_TEXT_ONLY_PRONUNCIATION}
                  </Select.Option>
                </Select>
              </div>
              {pane.type === QUESTION_TYPE.BUILD_SENTENCE ? (
                <div>
                  {new Array(8).fill(null).map((_, arrayIndex) => {
                    return (
                      <div
                        key={`build-sentence-${arrayIndex}`}
                        style={{marginTop: '20px', marginBottom: '20px'}}>
                        <Text>
                          Question{' '}
                          {arrayIndex < 4 ? 'first part of' : 'second part of'}{' '}
                          sentence {arrayIndex + 1}
                        </Text>
                        <Input
                          value={panes[index].choices[arrayIndex]}
                          onChange={(e) => {
                            let tempPanes = [...panes];
                            //@ts-ignore
                            tempPanes[index].choices[arrayIndex] =
                              e.target.value;
                            console.log('tempPanes[index]->', tempPanes[index]);
                            setPanes(tempPanes);
                          }}
                          placeholder={`Question sentence ${arrayIndex + 1}`}
                        />
                      </div>
                    );
                  })}
                </div>
              ) : pane.type === QUESTION_TYPE.CHOICES ? (
                <div>
                  {new Array(4).fill(null).map((_, arrayIndex) => {
                    return (
                      <div
                        key={`choice-${arrayIndex}`}
                        style={{marginTop: '20px', marginBottom: '20px'}}>
                        <Text>Choice number {arrayIndex + 1}</Text>
                        <Input
                          value={panes[index].choices[arrayIndex]}
                          onChange={(e) => {
                            let tempPanes = [...panes];
                            //@ts-ignore
                            tempPanes[index].choices[arrayIndex] =
                              e.target.value;

                            setPanes(tempPanes);
                          }}
                          placeholder={`Choice number ${arrayIndex + 1}`}
                        />
                      </div>
                    );
                  })}

                  <div style={{marginTop: '20px', marginBottom: '20px'}}>
                    <Text>Question text</Text>
                    <Input
                      value={panes[index].text}
                      onChange={(e) => {
                        let tempPanes = [...panes];
                        tempPanes[index].text = e.target.value;
                        setPanes(tempPanes);
                      }}
                      placeholder="Question text"
                    />
                  </div>
                </div>
              ) : (
                <div style={{marginTop: '20px', marginBottom: '20px'}}>
                  <Text>Question text</Text>
                  <Input
                    value={panes[index].text}
                    onBlur={() => {
                      if (
                        panes[index].text &&
                        pane.type === QUESTION_TYPE.COMPREHENSION
                      ) {
                        const choicesOptions = pane.text.split(' ');
                        let newOptions = {};
                        const numOfchoiceOptions = choicesOptions.filter(
                          (text) => text.startsWith('CHOICES'),
                        );
                        if (numOfchoiceOptions.length === 0) {
                          alert(
                            'Must Text Contains at least one keyword CHOICES1',
                          );
                        }
                        numOfchoiceOptions.forEach(
                          (value) => (newOptions[value] = ['', '']), // Initialize with two empty strings
                        );
                        let tempPanes = [...panes];
                        //@ts-ignore
                        tempPanes[index].customChoices = newOptions;

                        // Initialize choicesCount for each choice option
                        //@ts-ignore
                        if (!tempPanes[index].choicesCount) {
                          //@ts-ignore
                          tempPanes[index].choicesCount = {};
                        }

                        // Initialize correctIndex if it doesn't exist
                        //@ts-ignore
                        if (!tempPanes[index].correctIndex) {
                          //@ts-ignore
                          tempPanes[index].correctIndex = {};
                        }

                        numOfchoiceOptions.forEach((value) => {
                          //@ts-ignore
                          if (!tempPanes[index].choicesCount[value]) {
                            //@ts-ignore
                            tempPanes[index].choicesCount[value] = 2;
                          }
                        });

                        // Store original text for correct answer replacement
                        //@ts-ignore
                        tempPanes[index].originalTextAI = tempPanes[index].text;

                        // Set the first choice as active
                        if (numOfchoiceOptions.length > 0) {
                          setSelectChoice(numOfchoiceOptions[0]);
                        }

                        setPanes(tempPanes);
                      } else {
                        let tempPanes = [...panes];

                        //@ts-ignore
                        tempPanes[index].customChoices = {};
                        //@ts-ignore
                        tempPanes[index].choicesCount = {};
                        //@ts-ignore
                        tempPanes[index].correctIndex = {};
                        //@ts-ignore
                        tempPanes[index].originalTextAI =
                          tempPanes[index].text || '';
                        setPanes(tempPanes);
                      }
                    }}
                    onChange={(e) => {
                      let tempPanes = [...panes];
                      tempPanes[index].text = e.target.value;

                      if (
                        tempPanes[index].type === QUESTION_TYPE.COMPREHENSION
                      ) {
                        tempPanes[index].textAI = e.target.value;
                        // store the original ai text to able the user change the correct answer
                        //@ts-ignore
                        tempPanes[index].originalTextAI = e.target.value;
                      } else {
                        tempPanes[index].textAI = cleanStringForAI(
                          e.target.value,
                        );
                      }

                      setPanes(tempPanes);
                    }}
                    placeholder="Question text"
                  />
                </div>
              )}
              {QUESTION_TYPE_DICT[pane.type] == 'NORMAL_SCORE' && (
                <div
                  style={{
                    marginTop: '20px',
                    marginBottom: '20px',
                    border: '2px solid red',
                  }}>
                  <Text>Question AI text</Text>
                  <Input
                    value={panes[index].textAI}
                    onChange={(e) => {
                      let tempPanes = [...panes];

                      if (
                        tempPanes[index].type === QUESTION_TYPE.COMPREHENSION
                      ) {
                        tempPanes[index].textAI = e.target.value;
                      } else {
                        tempPanes[index].textAI = cleanStringForAI(
                          e.target.value,
                        );
                      }

                      setPanes(tempPanes);
                    }}
                    placeholder="Question AI text"
                  />
                </div>
              )}
              {pane.type === QUESTION_TYPE.COMPREHENSION && (
                // Select choices for comprehension question
                <div>
                  <div>
                    <Text style={{display: 'block'}}>Choice Options </Text>

                    <Select
                      value={selectChoice}
                      showSearch
                      style={{width: '30%'}}
                      placeholder={'Select a choice'}
                      optionFilterProp="children"
                      onChange={(selectedChoice: string) => {
                        setSelectChoice(selectedChoice);
                        let tempPanes = [...panes];
                        setPanes(tempPanes);
                      }}>
                      {Object.keys(panes[index]?.customChoices || {}).map(
                        (choice) => (
                          <Select.Option key={choice} value={choice}>
                            {choice}
                          </Select.Option>
                        ),
                      )}
                    </Select>
                  </div>
                  {selectChoice && (
                    <div>
                      {/* set 2 choices as an initial choices  */}
                      {/* @ts-ignore */}
                      {new Array(
                        //@ts-ignore
                        Math.max(
                          (panes[index]?.customChoices?.[selectChoice] &&
                            Array.isArray(
                              panes[index]?.customChoices?.[selectChoice],
                            ) &&
                            panes[index]?.customChoices?.[selectChoice]
                              .length) ||
                            0,
                          2, // Always ensure at least 2 choices
                        ),
                      )
                        .fill(null)
                        .map((_, arrayIndex) => {
                          const pane = panes[index];
                          // Ensure customChoices is always an array
                          const customChoices = Array.isArray(
                            pane?.customChoices?.[selectChoice],
                          )
                            ? pane?.customChoices?.[selectChoice]
                            : ['', ''];
                          return (
                            <div
                              key={arrayIndex}
                              style={{marginTop: '20px', marginBottom: '20px'}}>
                              <Row align="middle">
                                <Text>
                                  Choice number {arrayIndex + 1}
                                  <Checkbox
                                    style={{marginLeft: '10px'}}
                                    checked={
                                      //@ts-ignore
                                      pane?.correctIndex?.[selectChoice] ===
                                      arrayIndex
                                    }
                                    onChange={() => {
                                      if (!pane) return;
                                      let tempPanes = [...panes];
                                      //@ts-ignore
                                      if (!tempPanes[index].correctIndex) {
                                        //@ts-ignore
                                        tempPanes[index].correctIndex = {};
                                      }
                                      //@ts-ignore
                                      tempPanes[index].correctIndex[
                                        selectChoice
                                      ] = arrayIndex;

                                      let aiText =
                                        //@ts-ignore
                                        tempPanes[index].originalTextAI || '';
                                      let normalText =
                                        tempPanes[index].text || '';
                                      Object.keys(
                                        //@ts-ignore
                                        tempPanes[index].correctIndex,
                                      ).forEach((choiceKey) => {
                                        const correctIndex =
                                          //@ts-ignore
                                          tempPanes[index].correctIndex[
                                            choiceKey
                                          ];
                                        const selectedValue =
                                          tempPanes[index].customChoices[
                                            choiceKey
                                          ]?.[correctIndex] || choiceKey;

                                        aiText = aiText.replace(
                                          new RegExp(choiceKey, 'g'),
                                          selectedValue,
                                        );

                                        const cleanedText = normalText.replace(
                                          new RegExp(
                                            `\\b${choiceKey}(\\s+${choiceKey})*\\b`,
                                            'g',
                                          ),
                                          choiceKey,
                                        );

                                        const wordCount = selectedValue
                                          .trimEnd()
                                          .split(/\s+/).length;
                                        const duplicatedKey = Array(wordCount)
                                          .fill(choiceKey)
                                          .join(' ');
                                        normalText = cleanedText.replace(
                                          new RegExp(choiceKey, 'g'),
                                          duplicatedKey,
                                        );
                                      });
                                      tempPanes[index].text = normalText;
                                      tempPanes[index].textAI = aiText;

                                      setPanes(tempPanes);
                                    }}
                                  />{' '}
                                  Correct answer
                                </Text>
                              </Row>
                              <Input
                                value={customChoices[arrayIndex] || ''}
                                onChange={(e) => {
                                  if (!pane) return;
                                  let tempPanes = [...panes];

                                  // Initialize customChoices[selectChoice] as an array if it doesn't exist
                                  if (
                                    !tempPanes[index].customChoices[
                                      selectChoice
                                    ] ||
                                    !Array.isArray(
                                      tempPanes[index].customChoices[
                                        selectChoice
                                      ],
                                    )
                                  ) {
                                    tempPanes[index].customChoices[
                                      selectChoice
                                    ] = ['', ''];
                                  }

                                  // Get the current choices array
                                  let currentChoices = [
                                    ...tempPanes[index].customChoices[
                                      selectChoice
                                    ],
                                  ];

                                  // Ensure we have at least 2 choices
                                  while (currentChoices.length < 2) {
                                    currentChoices.push('');
                                  }

                                  // Update the value at the specified index
                                  currentChoices[arrayIndex] = e.target.value;

                                  // Update the customChoices in the state
                                  tempPanes[index].customChoices[selectChoice] =
                                    currentChoices;
                                  setPanes(tempPanes);
                                }}
                                placeholder={`Choice number ${arrayIndex + 1}`}
                              />
                            </div>
                          );
                        })}
                      {/* Add new choice button  */}
                      <Button
                        type="dashed"
                        onClick={() => {
                          let tempPanes = [...panes];
                          // Initialize customChoices[selectChoice] as an array if it doesn't exist
                          if (
                            !tempPanes[index].customChoices[selectChoice] ||
                            !Array.isArray(
                              tempPanes[index].customChoices[selectChoice],
                            )
                          ) {
                            tempPanes[index].customChoices[selectChoice] = [
                              '',
                              '',
                            ];
                          }

                          // Ensure we have at least 2 choices
                          let customChoices =
                            tempPanes[index].customChoices[selectChoice];
                          while (customChoices.length < 2) {
                            customChoices.push('');
                          }
                          tempPanes[index].customChoices[selectChoice] =
                            customChoices;

                          let currentCount = customChoices.length;

                          if (currentCount < 4) {
                            //@ts-ignore
                            if (!tempPanes[index].choicesCount) {
                              //@ts-ignore
                              tempPanes[index].choicesCount = {};
                            }
                            if (selectChoice) {
                              //@ts-ignore
                              tempPanes[index].choicesCount[selectChoice] =
                                currentCount + 1;

                              // add new empty choice
                              tempPanes[index].customChoices[selectChoice].push(
                                '',
                              );
                            }
                            setPanes(tempPanes);
                          }
                        }}
                        //@ts-ignore
                        disabled={
                          //@ts-ignore
                          panes[index]?.choicesCount?.[selectChoice] >= 4
                        }
                        style={{marginTop: '10px'}}>
                        Add Choice
                      </Button>
                    </div>
                  )}
                </div>
              )}
              <div>
                <Text style={{fontSize: '20', fontWeight: 'bold'}}>
                  Question asset
                </Text>

                <CreateAssetBlock
                  asset={panes[index].asset}
                  defaultAssetType={
                    panes[index]?.asset?.type ?? AssetType.PHOTO
                  }
                  // @ts-ignore TODO be checked
                  onChange={(asset) => {
                    // console.log(
                    //   'panes[index].asset.type',
                    //   panes[index].asset.type,
                    // );

                    let tempPanes = [...panes];
                    tempPanes[index].asset = asset;
                    setPanes(tempPanes);
                  }}
                />
              </div>
              {QUESTION_TYPE_DICT[pane.type] == 'NORMAL_SCORE' && (
                <>
                  <div>
                    <Text style={{fontSize: '20', fontWeight: 'bold'}}>
                      Question correct answer asset file url
                    </Text>

                    {panes[index]?.correctAsset?.fileUri ? (
                      <Row
                        style={{
                          display: 'flex',
                          // justifyContent: 'center',
                          alignItems: 'center',
                          alignContent: 'center',
                        }}>
                        <Input
                          style={{width: '80%'}}
                          value={panes[index]?.correctAsset?.fileUri}
                          onChange={(e) => {}}
                          placeholder="Correct answer url"
                        />

                        <CloseCircleFilled
                          style={{marginInline: 20}}
                          onClick={() => {
                            setFileAudio(null);

                            let tempAsset = {
                              fileUri: '',
                              type: AssetType.AUDIO,
                              thumbnailUri: '',
                            };
                            let tempPanes = [...panes];
                            // @ts-ignore
                            tempPanes[index].correctAsset = tempAsset;
                            setPanes(tempPanes);
                          }}
                        />
                      </Row>
                    ) : (
                      <Row>
                        <Upload {...propsAudioUpload}>
                          <Button
                            disabled={fileAudio ? true : false}
                            icon={<UploadOutlined />}>
                            Select File
                          </Button>
                        </Upload>

                        <Button
                          type="primary"
                          onClick={() =>
                            handleUpload(
                              fileAudio,
                              UploadFileType.TEST_MEDIA,
                              index,
                            )
                          }
                          disabled={!fileAudio}
                          loading={uploading}>
                          {uploading ? 'Uploading' : 'Upload'}
                        </Button>
                      </Row>
                    )}
                  </div>
                </>
              )}
            </TabPane>
          ))}
        </Tabs>

        <Button
          onClick={async () => {
            setIsLoading(true);
            try {
              let test: any = {
                id: selectedtest.id,
                updatedAt: selectedtest.updatedAt,
                questions: panes,
                type: testType,
                name: testName,
                description: testDescription,
                summary: testSummary,
                asset: testAsset,
              };

              console.log('ASSSSS', test);
              // @ts-ignore
              let testValidationResult = validateCreateTest(test);

              if (!testValidationResult?.status) {
                window.alert(testValidationResult.message);
                setIsLoading(false);
                return;
              }

              const response = await testService.updateTest(test, selectedtest);
              window.alert('Test with ID: ' + response?.id + ' updated');
              history.push('/tests');
            } catch (error) {
              window.alert('Error updating test');
            } finally {
              setIsLoading(false);
            }
          }}>
          Edit
        </Button>
      </div>
      <Modal
        okButtonProps={{hidden: true}}
        cancelButtonProps={{hidden: true}}
        visible={isLoading}
        closable={true}>
        <div>
          <Spin size="large" style={{margin: 'auto', width: '100%'}} />
          <p style={{textAlign: 'center'}}>Loading...</p>
        </div>
      </Modal>
    </>
  );
};
