import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Col, Input, Row, Spin, Table} from 'antd';

import {Test} from '../../graphql/API';
import {TestsService} from './TestsService';
import Title from 'antd/es/typography/Title';
import Text from 'antd/es/typography/Text';

const testsService = new TestsService();

export const TestsTable = () => {
  const [tests, setTests] = useState<Array<Test> | []>([]);
  const [testsSearchResult, setTestsSearchResult] = useState<Array<Test> | []>(
    [],
  );
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
    total: 0,
    nextToken: null,
  });

  useEffect(() => {
    testsService
      .getTests(null, pagination.pageSize)
      .then((resp) => {
        setPagination({
          ...pagination, // Spread the existing pagination state
          nextToken: resp.nextToken, // Update the nextToken
          total: resp.scannedCount + pagination.pageSize,
        });

        setIsLoading(false);
        setTests(resp.items);
      })
      .catch((error) => {
        setIsError(true);
        console.log('Error ->', error);
      });
  }, []);

  const getNextTests = async (
    nextToken: string | null,
    count: number,
    nextPage: number,
  ) => {
    if (pagination.nextToken !== null) {
      testsService
        .getTests(nextToken, count)
        .then((resp) => {
          setPagination({
            ...pagination, // Spread the existing pagination state
            nextToken: resp.nextToken, // Update the nextToken
            total: resp.nextToken
              ? pagination.total + pagination.pageSize
              : pagination.total,
            current: nextPage,
          });
          let newTestsList = tests.concat(resp.items);
          setIsLoading(false);
          setTests(newTestsList);
        })
        .catch((error) => {
          setIsError(true);
          console.log('Error ->', error);
        });
    } else {
      setPagination({
        ...pagination, // Spread the existing pagination state
        current: nextPage,
      });
    }
  };

  const handleTableChange = async (pagination: any, filters, sorter) => {
    await getNextTests(pagination.nextToken, 50, pagination.current);
  };

  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a: any, b: any) => a.id.length - b.id.length,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (id: string) => <Link to={'/tests/' + id}>{id}</Link>,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a: any, b: any) => {
        return a?.name.localeCompare(b?.name);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: (a: any, b: any) => {
        return a?.type.localeCompare(b?.type);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => {
        return a?.createdAt.localeCompare(b?.createdAt);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'Updated at',
      dataIndex: 'updatedAt',
      defaultSortOrder: 'descend',
      sorter: (a: any, b: any) => {
        return a?.updatedAt.localeCompare(b?.updatedAt);
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ];

  return (
    <>
      <Button size={'large'} style={{float: 'right', margin: 20}}>
        <Link to={'/tests/create'}>Create</Link>
      </Button>
      {!isError && !isLoading && (
        <>
          <Row
            style={{
              margin: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Col>
              <Text style={{fontWeight: 'bold'}}>
                Search by test name (Case sensitive)
              </Text>
              <Input
                style={{marginTop: 10, width: '50%'}}
                placeholder="Test name (Case sensitive)"
                onChange={(event) => {
                  if (event.target.value.length > 3) {
                    testsService
                      .getTestByName(event.target.value)
                      .then((testsResult) => {
                        setTestsSearchResult(testsResult);
                      });
                  } else if (event.target.value.length === 0) {
                    setTestsSearchResult([]);
                  }
                }}
              />
            </Col>
          </Row>
          <Table
            pagination={pagination}
            columns={columns}
            dataSource={
              testsSearchResult.length !== 0
                ? testsSearchResult?.map((test) => ({...test, key: test.id}))
                : tests?.map((test) => ({...test, key: test.id}))
            }
            rowKey={(record) => record.id}
            onChange={handleTableChange}
          />
        </>
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Tests fetching error ⚠️</Title>}
    </>
  );
};
