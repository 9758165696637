import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  Button,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tabs,
  Typography,
  Upload,
  Checkbox,
} from 'antd';

import {CreateAssetBlock} from '../../components';
import {
  Asset,
  AssetType,
  Lesson,
  ProgramType,
  Question,
  QUESTION_TYPE,
  TestTypes,
} from '../../graphql/API';
import {TestsService} from './TestsService';
import {validateCreateTest} from '../../utils/Validators';
import {LessonsService} from '../Lesson';
import {CloseCircleFilled, UploadOutlined} from '@ant-design/icons';
import {UploadFile, UploadProps} from 'antd/lib/upload/interface';
import {StorageService, UploadFileType} from '../Lesson/StorageService';
import {CreateAssetType} from '../../components/CreateAssetType';
import {CreateAssetUpload} from '../../components/CreateAssetUpload';
import {QUESTION_TYPE_DICT} from './QuestionTypeDict';
const testService = new TestsService();
const lessonsService = new LessonsService();
const storageService = new StorageService();

export const TestCreate = () => {
  const history = useHistory();
  const {Title, Text} = Typography;
  const {TabPane} = Tabs;
  const emptyTestPane = {
    name: '',
    //@ts-ignore
    correctAsset: {
      type: AssetType.AUDIO,
      thumbnailUri: '',
      fileUri: '',
    },
    //@ts-ignore
    asset: {
      type: AssetType.PHOTO,
      thumbnailUri: '',
      fileUri: '',
    },
    programType: ProgramType.LANGUAGE,
    summary: 'Watch carefully and record with your own voice.',
    text: '',
    textAI: '',
    originalTextAI: '',
    correctIndex: {},
    choices: [],
    choicesCount: [],
    type: QUESTION_TYPE.READ_SENTENCE,
  };

  //@ts-ignore
  const [panes, setPanes] = useState<Array<Question>>([emptyTestPane]);
  const [activeKey, setActiveKey] = useState<any>(0);

  const [testName, setTestName] = useState<string>('');
  const [lessonsList, setLessons] = useState<Lesson[]>([]);
  const [selectedLessonId, setSelectedLessonId] = useState<string>();

  const [fileAudio, setFileAudio] = useState<UploadFile>();

  const [uploading, setUploading] = useState(false);
  const [fileSummaryVideo, setFileSummaryVideo] = useState<UploadFile>();

  const [selectChoice, setSelectChoice] = useState<string>('');

  const [testType, setTestType] = useState<TestTypes | undefined>(
    TestTypes.QUIZ,
  );
  // @ts-ignore
  const [testAsset, setTestAsset] = useState<Asset | undefined>({
    type: AssetType.VIDEO,
    thumbnailUri: '',
    fileUri: '',
  });
  const [testDescription, setTestDescription] = useState<string>(
    'Watch the video at least 2 times and after that record the questions’ answers.',
  );
  const [testSummary, setTestSummary] = useState<string>(
    'Watch carefully at least 2 times and answer the questions.',
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [questionName, setQuestionName] = useState<string>('');
  const [questionDescription, setQuestionDescription] = useState<string>('');
  const [questionSummary, setQuestionSummary] = useState<string>('');
  useEffect(() => {
    lessonsService
      .getLessons()
      .then((lessons) => {
        //console.log('sfdsfsd_lessons', lessons);
        setLessons(lessons);
      })
      .catch((error) => {});
    return () => {};
  }, []);

  const onChange = (activeKey: string) => {
    setActiveKey(parseInt(activeKey));
    setFileSummaryVideo(null);
  };

  const onEdit = (targetKey: any, action: any) => {
    switch (action) {
      case 'add':
        add();
        break;
      default:
    }
  };

  const add = () => {
    const activeKey = panes.length;
    const newPanes = panes;
    // @ts-ignore
    newPanes.push(emptyTestPane);
    setPanes(newPanes);
    setActiveKey(activeKey);
  };
  const propsAudioUpload: UploadProps = {
    type: 'select',
    accept: '.aac',
    listType: 'text',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      setFileAudio(null);
    },
    beforeUpload: (file) => {
      setFileAudio(file);

      return false;
    },
  };

  const propsSummaryVideo: UploadProps = {
    type: 'select',
    accept: '.mp4,.aac',
    listType: 'text',
    multiple: false,
    maxCount: 1,
    onRemove: (file) => {
      setFileSummaryVideo(null);
    },
    beforeUpload: (file) => {
      setFileSummaryVideo(file);

      return false;
    },
  };

  const handleUpload = async (
    fileUri: UploadFile,
    typeUpload: UploadFileType,
    index: number,
  ) => {
    setUploading(true);
    try {
      let filePath: string;
      switch (typeUpload) {
        case UploadFileType.TEST_MEDIA:
          filePath = await storageService.uploadFile(
            fileUri,
            typeUpload,
            'test/media',
          );
          let tempAsset = {
            fileUri: filePath,
            type: AssetType.AUDIO,
            thumbnailUri: '',
          };
          let tempPanes = [...panes];
          // @ts-ignore
          tempPanes[index].correctAsset = tempAsset;
          setPanes(tempPanes);
          break;

        case UploadFileType.TEST_MEDIA_VIDEO_SESSION:
          filePath = await storageService.uploadFile(
            fileUri,
            typeUpload,
            'test/media',
          );
          let tempPaness = [...panes];
          // @ts-ignore
          tempPaness[index].videoAISessionUri = filePath;
          setPanes(tempPaness);
          break;

        default:
          break;
      }

      //console.log('file_PATH_IUPLOADED', filePath);
      message.success('upload successfully.');

      setUploading(false);
    } catch (error) {
      setUploading(false);
      message.error('upload failed.');
    }
  };

  const handleTestAssetTypeChange = (asset) => {
    if (asset.type === 'PHOTO') {
      setTestDescription('Watch carefully and record with your own voice.');
      setTestSummary('Record with your own voice');
      setTestAsset(asset);
    } else if (asset.type === 'VIDEO') {
      setTestDescription(
        'Watch the video at least 2 times and after that record the questions’ answers.',
      );
      setTestSummary(
        'Watch carefully at least 2 times and answer the questions.',
      );
      setTestAsset(asset);
    } else if (asset.type === 'AUDIO') {
      setTestDescription('');
      setTestSummary('');
      setTestAsset(asset);
    }
    // //console.log("TestAsset",testAsset,asset.type);
  };
  const handleQuestionTypeChange = (
    type: string,
    asset: string,
    index?: number,
  ) => {
    if (type === 'READ_SENTENCE') {
      if (asset === 'VIDEO') {
        setQuestionSummary(
          'Watch the video at least 2 times and after that record the questions’ answers.',
        );
      } else if (asset === 'PHOTO') {
        setQuestionSummary('Watch carefully and record with your own voice.');
      } else {
        setQuestionSummary('');
      }
    } else if (type === 'CHOICES') {
      if (asset === 'VIDEO') {
        setQuestionSummary(
          'Watch the video at least 2 times and after that answer the questions.',
        );
      } else if (asset === 'PHOTO') {
        setQuestionSummary('Watch carefully and answer the questions.');
      } else {
        setQuestionSummary('');
      }
    } else if (type === 'MISSING_LETTERS') {
      if (asset === 'VIDEO') {
        setQuestionSummary(
          'Watch the video at least 2 times and after that answer the questions.',
        );
      } else if (asset === 'PHOTO') {
        setQuestionSummary('Record the word after adding the missing letters.');
      } else {
        setQuestionSummary('');
      }
    } else if (type === 'MISSING_WORD') {
      if (asset === 'VIDEO') {
        setQuestionSummary(
          'Watch the video at least 2 times and after that record the question’s answers.',
        );
      } else if (asset === 'PHOTO') {
        setQuestionSummary(
          'Record the sentence after adding the missing words.',
        );
      } else {
        setQuestionSummary('');
      }
    } else if (type === 'COMPREHENSION') {
      if (asset === 'VIDEO') {
        setQuestionSummary(
          'Watch the video at least 2 times and after that add the missing words from the choices.',
        );
      } else if (asset === 'PHOTO') {
        setQuestionSummary(
          'Complete the paragraph by adding the missing words.',
        );
      } else {
        setQuestionSummary('');
      }
    } else if (type === 'TRANSCRIBE_AND_SCORE') {
      setQuestionSummary(
        "Create questions to evaluate users' spoken responses, focusing on pronunciation accuracy and clarity.",
      );
    }
  };

  function cleanStringForAI(input) {
    return input
      .toLowerCase() // Convert to lowercase
      .replace(/[^a-z0-9'\s]/g, ''); // Remove special characters and punctuation, except apostrophe
  }

  return (
    <>
      <div style={{padding: 10}}>
        <Title style={{textAlign: 'center'}}>Create test </Title>
        <>
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text>Test name </Text>
            <Input
              onChange={(e) => {
                setTestName(e.target.value);
                setQuestionName(e.target.value);
                let newPanes = [...panes];
                newPanes[0].name = e.target.value;
                setPanes(newPanes);
              }}
              placeholder="Test name"
            />
          </div>
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text style={{display: 'block'}}>Test type </Text>
            <Select
              value={testType}
              showSearch
              style={{width: '100%'}}
              placeholder="Select a test type"
              optionFilterProp="children"
              onChange={(selectedTestType: TestTypes) => {
                setTestType(selectedTestType);
              }}>
              <Select.Option value={TestTypes.QUIZ}>
                {TestTypes.QUIZ}
              </Select.Option>
              <Select.Option value={TestTypes.PLACEMENT_TEST}>
                {TestTypes.PLACEMENT_TEST}
              </Select.Option>
              <Select.Option value={TestTypes.STORY}>
                {TestTypes.STORY}
              </Select.Option>
            </Select>
          </div>
          <CreateAssetType
            defaultAssetType={AssetType.VIDEO}
            // @ts-ignore TODO be checked
            onChange={(selectedAsset) =>
              handleTestAssetTypeChange(selectedAsset)
            }
          />
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text>Test description </Text>
            <Input
              value={testDescription}
              onChange={(e) => {
                setTestDescription(e.target.value);
              }}
              placeholder="Test description"
            />
          </div>
          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text>Test summary </Text>
            <Input
              value={testSummary}
              onChange={(e) => {
                setTestSummary(e.target.value);
              }}
              placeholder="Test summary"
            />
          </div>

          <CreateAssetUpload
            defaultAssetType={testAsset.type}
            asset={testAsset}
            // @ts-ignore TODO be checked
            onChange={(selectedAsset) => setTestAsset(selectedAsset)}
          />

          <div style={{marginTop: '20px', marginBottom: '20px'}}>
            <Text style={{display: 'block'}}>Add Test to Lesson</Text>
            <Select
              value={selectedLessonId}
              showSearch
              style={{width: 200}}
              placeholder="add test to lesson"
              optionFilterProp="children"
              onChange={(lessId: string) => {
                setSelectedLessonId(lessId);
              }}>
              {lessonsList.map((less: Lesson, index) => (
                <Select.Option value={less.id} key={index}>
                  {less.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </>

        <Tabs
          type="editable-card"
          onChange={onChange}
          activeKey={activeKey.toString()}
          onEdit={onEdit}>
          {panes?.map((pane, index) => (
            <TabPane
              tab={'question_' + index}
              key={index}
              closable={false}
              style={{
                border: '2px solid gray',
                padding: '10px',
              }}>
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <Text>Question name</Text>
                <Input
                  onChange={(e) => {
                    let tempPanes = [...panes];
                    tempPanes[index].name = e.target.value;
                    setPanes(tempPanes);
                  }}
                  placeholder="Question name"
                  value={panes[index].name}
                />
              </div>
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <Text style={{display: 'block'}}>Question type </Text>
                <Select
                  value={panes[index].type}
                  showSearch
                  style={{width: 300}}
                  placeholder="Select a question type"
                  optionFilterProp="children"
                  onChange={(selectedQuestionType: any) => {
                    let tempPanes = [...panes];
                    console.log('selectedQuestionType->', selectedQuestionType);
                    tempPanes[index].type = selectedQuestionType;
                    //@ts-ignore
                    tempPanes[index].customChoices = [];
                    setPanes(tempPanes);
                    handleQuestionTypeChange(
                      selectedQuestionType,
                      panes[index].asset.type,
                      index,
                    );
                  }}>
                  <Select.Option value={QUESTION_TYPE.READ_SENTENCE}>
                    {QUESTION_TYPE.READ_SENTENCE} (default)
                  </Select.Option>
                  {/*MUST ENTER 4 Choices*/}
                  <Select.Option value={QUESTION_TYPE.CHOICES}>
                    {QUESTION_TYPE.CHOICES}
                  </Select.Option>
                  {/*MUST ENTER 8 Choices*/}
                  <Select.Option value={QUESTION_TYPE.BUILD_SENTENCE}>
                    {QUESTION_TYPE.BUILD_SENTENCE}
                  </Select.Option>
                  {/*MUST ENTER TEXTAI*/}
                  <Select.Option value={QUESTION_TYPE.MISSING_LETTERS}>
                    {QUESTION_TYPE.MISSING_LETTERS}
                  </Select.Option>
                  <Select.Option value={QUESTION_TYPE.MISSING_WORD}>
                    {QUESTION_TYPE.MISSING_WORD}
                  </Select.Option>
                  {/*MUST ENTER 4 Choices*/}
                  <Select.Option value={QUESTION_TYPE.COMPREHENSION}>
                    {QUESTION_TYPE.COMPREHENSION}
                  </Select.Option>
                  {/* TRANSCRIBE_AND_SCORE */}
                  <Select.Option value={QUESTION_TYPE.TRANSCRIBE_AND_SCORE}>
                    {QUESTION_TYPE.TRANSCRIBE_AND_SCORE}
                  </Select.Option>
                  {/* DESCRIBE_IMAGE_ONLY_PRONUNCIATION */}
                  <Select.Option
                    value={QUESTION_TYPE.DESCRIBE_IMAGE_ONLY_PRONUNCIATION}>
                    {QUESTION_TYPE.DESCRIBE_IMAGE_ONLY_PRONUNCIATION}
                  </Select.Option>
                  {/* FREE_TEXT_ONLY_PRONUNCIATION */}
                  <Select.Option
                    value={QUESTION_TYPE.FREE_TEXT_ONLY_PRONUNCIATION}>
                    {QUESTION_TYPE.FREE_TEXT_ONLY_PRONUNCIATION}
                  </Select.Option>
                </Select>
              </div>
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <Text>Question Summary</Text>
                <Input
                  value={
                    questionSummary !== ''
                      ? questionSummary
                      : panes[index].summary
                  }
                  onChange={(e) => {
                    let tempPanes = [...panes];
                    tempPanes[index].summary = e.target.value;
                    setPanes(tempPanes);
                  }}
                  placeholder="Question Summary"
                />
              </div>
              <div style={{marginTop: '20px', marginBottom: '20px'}}>
                <Text>Question description</Text>
                <Input
                  value={
                    questionDescription !== ''
                      ? questionDescription
                      : panes[index].description
                  }
                  onChange={(e) => {
                    let tempPanes = [...panes];
                    tempPanes[index].description = e.target.value;
                    setPanes(tempPanes);
                  }}
                  placeholder="Question description"
                />
              </div>

              {testType === TestTypes.STORY && (
                <div style={{marginTop: '20px', marginBottom: '20px'}}>
                  <Text>Video AI Session url </Text>

                  {panes[index].videoAISessionUri ? (
                    <Row
                      style={{
                        display: 'flex',
                        // justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                      }}>
                      <Input
                        style={{
                          width: '80%',
                        }}
                        value={panes[index].videoAISessionUri}
                        onChange={(e) => {}}
                        placeholder="Basic usage"
                      />
                      {panes[index].videoAISessionUri && (
                        <CloseCircleFilled
                          style={{marginInline: 20}}
                          onClick={() => {
                            let tempPanes = [...panes];
                            tempPanes[index].videoAISessionUri = null;
                            setPanes(tempPanes);
                            setFileSummaryVideo(null);
                          }}
                        />
                      )}
                    </Row>
                  ) : (
                    <Row>
                      <Upload {...propsSummaryVideo}>
                        <Button
                          disabled={fileSummaryVideo ? true : false}
                          icon={<UploadOutlined />}>
                          Select File
                        </Button>
                      </Upload>

                      <Button
                        type="primary"
                        onClick={() => {
                          handleUpload(
                            fileSummaryVideo,
                            UploadFileType.TEST_MEDIA_VIDEO_SESSION,
                            index,
                          );
                        }}
                        disabled={!fileSummaryVideo}
                        loading={uploading}>
                        {uploading ? 'Uploading' : 'Start Upload'}
                      </Button>
                    </Row>
                  )}
                </div>
              )}

              {pane.type === QUESTION_TYPE.BUILD_SENTENCE ? (
                <div>
                  {new Array(8).fill(null).map((_, arrayIndex) => {
                    return (
                      <div style={{marginTop: '20px', marginBottom: '20px'}}>
                        <Text>
                          Question{' '}
                          {arrayIndex < 4 ? 'first part of' : 'second part of'}{' '}
                          sentence {arrayIndex + 1}
                        </Text>
                        <Input
                          value={panes[index].choices[arrayIndex]}
                          onChange={(e) => {
                            let tempPanes = [...panes];
                            //@ts-ignore
                            tempPanes[index].choices[arrayIndex] =
                              e.target.value;
                            //console.log('tempPanes[index]->', tempPanes[index]);
                            setPanes(tempPanes);
                          }}
                          placeholder={`Question sentence ${arrayIndex + 1}`}
                        />
                      </div>
                    );
                  })}
                  <div
                    style={{
                      marginTop: '20px',
                      marginBottom: '20px',
                    }}>
                    <Text>
                      {QUESTION_TYPE.DESCRIBE_IMAGE_ONLY_PRONUNCIATION}{' '}
                    </Text>
                    <Input
                      value={panes[index].text}
                      onChange={(e) => {
                        let tempPanes = [...panes];
                        tempPanes[index].text = e.target.value;
                        if (
                          tempPanes[index].type === QUESTION_TYPE.COMPREHENSION
                        ) {
                          tempPanes[index].textAI = e.target.value;
                        } else {
                          tempPanes[index].textAI = cleanStringForAI(
                            e.target.value,
                          );
                        }

                        setPanes(tempPanes);
                      }}
                      placeholder="Question text"
                    />
                  </div>
                </div>
              ) : pane.type === QUESTION_TYPE.CHOICES ? (
                <div>
                  {new Array(4).fill(null).map((_, arrayIndex) => {
                    return (
                      <div
                        key={arrayIndex}
                        style={{marginTop: '20px', marginBottom: '20px'}}>
                        <Text>Choice number {arrayIndex + 1}</Text>
                        <Input
                          value={panes[index].choices[arrayIndex]}
                          onChange={(e) => {
                            let tempPanes = [...panes];
                            //@ts-ignore
                            tempPanes[index].choices[arrayIndex] =
                              e.target.value;

                            setPanes(tempPanes);
                          }}
                          placeholder={`Choice number ${arrayIndex + 1}`}
                        />
                      </div>
                    );
                  })}
                  <div
                    style={{
                      marginTop: '20px',
                      marginBottom: '20px',
                      border: '2px solid red',
                    }}>
                    <Text>Question text</Text>
                    <Input
                      value={panes[index].text}
                      onChange={(e) => {
                        let tempPanes = [...panes];
                        tempPanes[index].text = e.target.value;
                        tempPanes[index].textAI = cleanStringForAI(
                          e.target.value,
                        );
                        setPanes(tempPanes);
                      }}
                      placeholder="Question text"
                    />
                  </div>
                </div>
              ) : (
                <div style={{marginTop: '20px', marginBottom: '20px'}}>
                  <Text>Question text</Text>
                  <Input
                    value={panes[index].text}
                    onBlur={() => {
                      if (
                        panes[index].text &&
                        pane.type === QUESTION_TYPE.COMPREHENSION
                      ) {
                        const choicesOptions = pane.text.split(' ');
                        let newOptions = {};
                        const numOfchoiceOptions = choicesOptions.filter(
                          (text) => text.startsWith('CHOICES'),
                        );
                        if (numOfchoiceOptions.length === 0) {
                          alert(
                            'Must Text Contains at least one keyword CHOICES1',
                          );
                        }
                        numOfchoiceOptions.forEach(
                          (value) => (newOptions[value] = []),
                        );
                        let tempPanes = [...panes];
                        //@ts-ignore
                        tempPanes[index].customChoices = newOptions;

                        setPanes(tempPanes);
                      } else {
                        let tempPanes = [...panes];

                        //@ts-ignore
                        tempPanes[index].customChoices = {};
                        setPanes(tempPanes);
                      }
                    }}
                    onChange={(e) => {
                      let tempPanes = [...panes];
                      tempPanes[index].text = e.target.value;

                      if (
                        tempPanes[index].type === QUESTION_TYPE.COMPREHENSION
                      ) {
                        tempPanes[index].textAI = e.target.value;
                        // store the original ai text to able the user change the correct answer
                        //@ts-ignore
                        tempPanes[index].originalTextAI = e.target.value;
                      } else {
                        tempPanes[index].textAI = cleanStringForAI(
                          e.target.value,
                        );
                      }

                      setPanes(tempPanes);
                    }}
                    placeholder="Question text"
                  />
                </div>
              )}
              {QUESTION_TYPE_DICT[pane.type] == 'NORMAL_SCORE' && (
                <div
                  style={{
                    marginTop: '20px',
                    marginBottom: '20px',
                    border: '2px solid red',
                  }}>
                  <Text>Question AI text</Text>
                  <Input
                    value={panes[index].textAI}
                    onChange={(e) => {
                      let tempPanes = [...panes];

                      if (
                        tempPanes[index].type === QUESTION_TYPE.COMPREHENSION
                      ) {
                        tempPanes[index].textAI = e.target.value;
                      } else {
                        tempPanes[index].textAI = cleanStringForAI(
                          e.target.value,
                        );
                      }

                      setPanes(tempPanes);
                    }}
                    placeholder="Question AI text"
                  />
                </div>
              )}

              {pane.type === QUESTION_TYPE.COMPREHENSION && (
                <div>
                  <div>
                    <Text style={{display: 'block'}}>Choice Options </Text>

                    <Select
                      value={selectChoice}
                      showSearch
                      style={{width: '30%'}}
                      placeholder={'Select ' + selectChoice}
                      optionFilterProp="children"
                      onChange={(selectedChoice: string) => {
                        setSelectChoice(selectedChoice);
                        let tempPanes = [...panes];
                        setPanes(tempPanes);
                      }}>
                      {Object.keys(panes[index].customChoices).map((choice) => (
                        <Select.Option key={choice} value={choice}>
                          {choice}
                        </Select.Option>
                      ))}
                    </Select>
                  </div>
                  {selectChoice && (
                    <div>
                      {/* set 2 choices as an initial choices  */}
                      {/* @ts-ignore */}
                      {new Array(
                        //@ts-ignore
                        panes[index]?.choicesCount?.[selectChoice] || 2,
                      )
                        .fill(null)
                        .map((_, arrayIndex) => {
                          const pane = panes[index];
                          const customChoices =
                            pane?.customChoices?.[selectChoice] || [];
                          return (
                            <div
                              key={arrayIndex}
                              style={{marginTop: '20px', marginBottom: '20px'}}>
                              <Row align="middle">
                                <Text>
                                  Choice number {arrayIndex + 1}
                                  <Checkbox
                                    style={{marginLeft: '10px'}}
                                    checked={
                                      //@ts-ignore
                                      pane?.correctIndex?.[selectChoice] ===
                                      arrayIndex
                                    }
                                    onChange={() => {
                                      if (!pane) return;
                                      let tempPanes = [...panes];
                                      //@ts-ignore
                                      if (!tempPanes[index].correctIndex) {
                                        //@ts-ignore
                                        tempPanes[index].correctIndex = {};
                                      }
                                      //@ts-ignore
                                      tempPanes[index].correctIndex[
                                        selectChoice
                                      ] = arrayIndex;

                                      let aiText =
                                        //@ts-ignore
                                        tempPanes[index].originalTextAI || '';
                                      let normalText =
                                        tempPanes[index].text || '';
                                      Object.keys(
                                        //@ts-ignore
                                        tempPanes[index].correctIndex,
                                      ).forEach((choiceKey) => {
                                        const correctIndex =
                                          //@ts-ignore
                                          tempPanes[index].correctIndex[
                                            choiceKey
                                          ];
                                        const selectedValue =
                                          tempPanes[index].customChoices[
                                            choiceKey
                                          ]?.[correctIndex] || choiceKey;

                                        aiText = aiText.replace(
                                          new RegExp(choiceKey, 'g'),
                                          selectedValue,
                                        );

                                        const cleanedText = normalText.replace(
                                          new RegExp(
                                            `\\b${choiceKey}(\\s+${choiceKey})*\\b`,
                                            'g',
                                          ),
                                          choiceKey,
                                        );

                                        const wordCount = selectedValue
                                          .trimEnd()
                                          .split(/\s+/).length;
                                        const duplicatedKey = Array(wordCount)
                                          .fill(choiceKey)
                                          .join(' ');
                                        normalText = cleanedText.replace(
                                          new RegExp(choiceKey, 'g'),
                                          duplicatedKey,
                                        );
                                      });
                                      tempPanes[index].text = normalText;
                                      tempPanes[index].textAI = aiText;

                                      setPanes(tempPanes);
                                    }}
                                  />{' '}
                                  Correct answer
                                </Text>
                              </Row>
                              <Input
                                value={customChoices[arrayIndex] || ''}
                                onChange={(e) => {
                                  if (!pane) return;
                                  let tempPanes = [...panes];
                                  const currentChoices =
                                    tempPanes[index].customChoices[
                                      selectChoice
                                    ] || [];
                                  currentChoices[arrayIndex] = e.target.value;

                                  tempPanes[index].customChoices[selectChoice] =
                                    currentChoices;
                                  setPanes(tempPanes);
                                }}
                                placeholder={`Choice number ${arrayIndex + 1}`}
                              />
                            </div>
                          );
                        })}
                      <Button
                        type="dashed"
                        onClick={() => {
                          let tempPanes = [...panes];

                          let currentCount =
                            //@ts-ignore
                            tempPanes[index].choicesCount[selectChoice] || 2;

                          if (currentCount < 4) {
                            //@ts-ignore
                            tempPanes[index].choicesCount[selectChoice] =
                              currentCount + 1;
                            setPanes(tempPanes);
                          }
                        }}
                        //@ts-ignore
                        disabled={panes[index].choicesCount[selectChoice] >= 4}
                        style={{marginTop: '10px'}}>
                        Add Choice
                      </Button>
                    </div>
                  )}
                </div>
              )}
              <div>
                <Text style={{fontSize: '20', fontWeight: 'bold'}}>
                  Question asset
                </Text>
                <CreateAssetBlock
                  defaultAssetType={AssetType.PHOTO}
                  // @ts-ignore TODO be checked
                  onChange={(asset) => {
                    let tempPanes = [...panes];
                    tempPanes[index].asset = asset;
                    setPanes(tempPanes);
                    handleQuestionTypeChange(
                      panes[index].type,
                      asset.type,
                      index,
                    );
                  }}
                />
              </div>
              {QUESTION_TYPE_DICT[pane.type] == 'NORMAL_SCORE' && (
                <>
                  <div>
                    <Text style={{fontSize: '20', fontWeight: 'bold'}}>
                      Question correct answer asset file url
                    </Text>

                    {panes[index]?.correctAsset?.fileUri ? (
                      <Row
                        style={{
                          display: 'flex',
                          // justifyContent: 'center',
                          alignItems: 'center',
                          alignContent: 'center',
                        }}>
                        <Input
                          style={{width: '80%'}}
                          value={panes[index]?.correctAsset?.fileUri}
                          onChange={(e) => {}}
                          placeholder="Correct answer url"
                        />

                        <CloseCircleFilled
                          style={{marginInline: 20}}
                          onClick={() => {
                            setFileAudio(null);

                            let tempAsset = {
                              fileUri: '',
                              type: AssetType.AUDIO,
                              thumbnailUri: '',
                            };
                            let tempPanes = [...panes];
                            // @ts-ignore
                            tempPanes[index].correctAsset = tempAsset;
                            setPanes(tempPanes);
                          }}
                        />
                      </Row>
                    ) : (
                      <Row>
                        <Upload {...propsAudioUpload}>
                          <Button
                            disabled={fileAudio ? true : false}
                            icon={<UploadOutlined />}>
                            Select File
                          </Button>
                        </Upload>

                        <Button
                          type="primary"
                          onClick={() =>
                            handleUpload(
                              fileAudio,
                              UploadFileType.TEST_MEDIA,
                              index,
                            )
                          }
                          disabled={!fileAudio}
                          loading={uploading}>
                          {uploading ? 'Uploading' : 'Upload'}
                        </Button>
                      </Row>
                    )}
                  </div>
                </>
              )}
            </TabPane>
          ))}
        </Tabs>

        <Button
          onClick={async () => {
            // setIsLoading(true);
            let test = {
              questions: panes,
              type: testType,
              name: testName,
              description: testDescription,
              summary: testSummary,
              asset: testAsset,
              lessonId: selectedLessonId,
            };
            console.log(test);

            // @ts-ignore
            let testValidationResult = validateCreateTest(test);
            if (testValidationResult?.status) {
              await testService
                .createTest(test)
                .catch((e) => {
                  setIsLoading(true);
                  alert('Error creating test');
                })
                .then((response) => {
                  alert('Test with ID: ' + response?.id + ' created');
                  history.push('/tests');
                });
            } else {
              alert(testValidationResult.message);
              setIsLoading(false);
            }
          }}>
          Create
        </Button>
      </div>
      <Modal
        okButtonProps={{hidden: true}}
        cancelButtonProps={{hidden: true}}
        visible={isLoading}
        closable={true}>
        <div>
          <Spin size="large" style={{margin: 'auto', width: '100%'}} />
          <p style={{textAlign: 'center'}}>Loading...</p>
        </div>
      </Modal>
    </>
  );
};
