import {useEffect, useState} from 'react';
import {useParams, Link} from 'react-router-dom';
import {Button, Col, Row, Spin, Typography} from 'antd';
import {Enrollment} from '../../graphql/API';
import {EnrollmentsService} from './EnrollmentsService';
import {getFileUrlByKey} from '../../utils';
import {GetUrlOutput} from 'aws-amplify/storage';

const enrollmentsService = new EnrollmentsService();

export const EnrollmentDetails = () => {
  // 4fe2f51f-302e-40d1-ad9a-5d28d8e3fd69
  const [enrollment, setEnrollment] = useState<Enrollment | undefined>(
    undefined,
  );
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  let params: {enrollmentId: string} = useParams();

  const {Title, Text} = Typography;

  useEffect(() => {
    enrollmentsService
      .getEnrollmentById(params.enrollmentId)
      .then((resp) => {
        setIsLoading(false);
        setEnrollment(resp);
      })
      .catch((error) => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Enrollment </Title>
      <Button size={'large'} style={{float: 'right'}}>
        <Link
          // @ts-ignore
          to={{
            pathname: '/enrollments/' + enrollment?.id + '/edit',
            state: {enrollment: enrollment},
          }}>
          Edit
        </Link>
      </Button>
      {!isError && !isLoading && (
        <>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Student name
                </Text>
                <Link to={'/students/' + enrollment?.student?.id}>
                  {enrollment?.student?.name}
                </Link>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Started at
                </Text>
                <Text>{enrollment?.startDate}</Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Placement test status
                </Text>
                <Text>{enrollment?.placementStatus}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Payment status
                </Text>
                <Text>{enrollment?.paymentStatus}</Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Level
                </Text>
                <Text>{enrollment?.level?.name}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Package
                </Text>
                <Text>{enrollment?.package?.name}</Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Answer score
                </Text>
                <Text>{enrollment?.answer?.score}</Text>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Answer Audio
                </Text>
                <Button
                  onClick={async () => {
                    if (enrollment?.answer?.audioUri) {
                      let fileUrl: GetUrlOutput = await getFileUrlByKey(
                        enrollment?.answer?.audioUri,
                      );
                      fileUrl.url
                        ? window.open(fileUrl.url)
                        : alert('Getting file error');
                    } else {
                      alert("This file doesn't exist");
                    }
                  }}>
                  Hear student's answer
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Placement test
                </Text>
                <Link to={'/tests/' + enrollment?.test?.id}>
                  {enrollment?.test?.name}
                </Link>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Created at
                </Text>
                <Text>{enrollment?.createdAt}</Text>
              </div>
            </Col>
          </Row>
        </>
      )}
      {isLoading && (
        <Spin size="large" style={{margin: 'auto', width: '100%'}} />
      )}
      {isError && <Title>Enrollment fetching error ⚠️</Title>}
    </div>
  );
};
