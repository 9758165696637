export const QUESTION_TYPE_DICT = {
  MISSING_LETTERS: 'NORMAL_SCORE',
  MISSING_WORD: 'NORMAL_SCORE',
  CHOICES: 'NORMAL_SCORE',
  BUILD_SENTENCE: 'NORMAL_SCORE',
  READ_SENTENCE: 'NORMAL_SCORE',
  COMPREHENSION: 'NORMAL_SCORE',
  TRANSCRIBE_AND_SCORE: 'TRANSCRIBE_SCORE',
  DESCRIBE_IMAGE_ONLY_PRONUNCIATION: 'TRANSCRIBE_SCORE',
  FREE_TEXT_ONLY_PRONUNCIATION: 'TRANSCRIBE_SCORE',
};
