import {ITestsService} from './ITestsService';
import {TestsRepository} from './TestsRepository';
import {
  AssetUpdate,
  Question,
  Test,
  TestInsert,
  TestUpdate,
} from '../../graphql/API';
import {AssetsService} from '../Asset';
import {QuestionsService} from '../Questions';
import {AssetsRepository} from '../Asset/AssetsRepository';

export class TestsService implements ITestsService {
  testsRepository: TestsRepository;
  assetsService: AssetsService;
  questionsService: QuestionsService;
  assetsRepository: AssetsRepository;
  constructor() {
    this.testsRepository = new TestsRepository();
    this.assetsService = new AssetsService();
    this.questionsService = new QuestionsService();
    this.assetsRepository = new AssetsRepository();
  }

  async getTests(
    nextToken: string = null,
    scannedCount: number = null,
  ): Promise<Array<Test> | any> {
    let tests = await this.testsRepository.getTests(nextToken, scannedCount);
    return tests;
  }

  async getTestById(testId: string): Promise<Test> {
    return await this.testsRepository.getTestById(testId);
  }

  async createTest(test: TestInsert | any): Promise<any> {
    try {
      let testObj = {
        name: test.name,
        assetId: '',
        questionIds: [],
        type: test.type,
        description: test.description,
        summary: test.summary,
        lessonId: test.lessonId,
      };
      console.log('inside create test');
      testObj.assetId = await this.assetsService.createAsset(test.asset!);
      testObj.questionIds = await Promise.all(
        test.questions?.map(async (q: any) => {
          return await this.questionsService.createQuestion(q);
        }),
      );
      // //TODO: Add validators
      return await this.testsRepository.createTest(testObj);
    } catch (e) {
      return new Promise((resolve, reject) =>
        reject(`Creating test error: ${e}`),
      );
    }
  }

  async updateTest(test: Test, orginialTest: Test): Promise<any> {
    try {
      let testObj: TestUpdate = {
        name: test.name,
        type: test.type,
        description: test.description,
        summary: test.summary,
      };

      console.log('inside update test');
      let newQuestionsIds = [];
      // testObj.assetId = await this.assetsService.createAsset(test.asset!);
      await Promise.all(
        test.questions?.map(async (q: Question) => {
          if (q.id) {
            return await this.questionsService.updateQuestion(
              q.id,
              q,
              q.updatedAt,
            );
          } else {
            if (!q.choices) {
              q.choices = [];
            }
            let questionId = await this.questionsService.createQuestion(q);
            newQuestionsIds.push(questionId);
          }
        }),
      );

      if (
        !test?.asset?.id &&
        (test.asset?.fileUri || test.asset?.thumbnailUri)
      ) {
        testObj.assetId = await this.assetsRepository.createAsset(test.asset!);
      } else if (test?.asset?.id) {
        const assetUpdate: AssetUpdate = {
          type: test.asset.type,
          thumbnailUri: test.asset.thumbnailUri,
          fileUri: test.asset.fileUri,
          // title_i18n: test.asset.title_i18n,
          // subTitle_i18n: test.asset.subTitle_i18n,
          // position: test.asset.position,
        };
        await this.assetsRepository.updateAsset(
          test?.asset?.id,
          test?.asset?.updatedAt,
          assetUpdate,
        );
      }
      if (newQuestionsIds.length !== 0) {
        let questionIds = test.questions.map((q: Question) => q.id);
        testObj.questionIds = questionIds.concat(newQuestionsIds);
        testObj.questionIds = testObj.questionIds.filter((n) => n);
      } else {
        testObj.questionIds = test.questions.map((q: Question) => q.id);
      }

      //TODO: Add validators
      return await this.testsRepository.updateTest(
        test.id,
        testObj,
        test.updatedAt,
      );
    } catch (e) {
      return new Promise((resolve, reject) =>
        reject(`update test error: ${e}`),
      );
    }
  }

  async getTestByName(name: string): Promise<Array<Test>> {
    return await this.testsRepository.getTestsByName(name);
  }
}
