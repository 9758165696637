import {useEffect, useState} from 'react';

import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import {cloneDeep} from 'lodash';
import {Button, Checkbox, Col, Input, Row, Select, Typography} from 'antd';

import {AssetType, Program, Test} from '../../graphql/API';
import {EditAssetsBlock, InputI18nStringBlock, Loading} from '../../components';
import {ProgramsService} from '../Program';
import {TestsService} from '../Test';
import {resolveI18nObject} from '../../utils';

const programsService = new ProgramsService();
const testsService = new TestsService();

export const ProgramEdit = () => {
  const history = useHistory();

  const location: {
    state: any;
    hash: any;
    search: any;
    pathname: any;
  } = useLocation();

  const {Title, Text} = Typography;

  const [isError, setIsError] = useState<boolean>(false);

  let params: {programId: string} = useParams();
  const [program, setProgram] = useState<Program>();
  const [tests, setTests] = useState<Array<Test> | []>();

  useEffect(() => {
    if (location.state?.program) {
      setProgram(location.state.program);
    } else {
      programsService
        .getProgramById(params.programId)
        .then((prog: Program) => {
          setProgram(prog);
        })
        .catch((e) => {
          //console.log('Fetching Programs error', e);
          setIsError(true);
        });
    }
  }, []);

  const onChange = (
    prop: any,
    value: any,
    index?: number,
    isDelete?: boolean,
  ) => {
    if (prop.includes('.')) {
      let propList = prop.split('.');
      let p0 = propList[0];
      let p1 = propList[1];
      // @ts-ignore
      setProgram({
        ...program,
        // @ts-ignore
        [p0]: {...program[p0], [p1]: value},
      });
    } else {
      // @ts-ignore
      setProgram({...program, [prop]: value});
    }
  };

  return (
    <div style={{padding: 10}}>
      <Title style={{textAlign: 'center'}}>Edit program </Title>
      {program ? (
        <>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <InputI18nStringBlock
                  fieldName={'Name'}
                  stringifiedJson={program?.name_i18n}
                  onChange={(e) => {
                    setProgram({...program, name_i18n: e});
                  }}
                />
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Checkbox
                  checked={program?.supportAi}
                  onChange={(event) =>
                    onChange('supportAi', event.target.checked)
                  }>
                  Program supporting Ai?
                </Checkbox>
              </div>

              <div style={{margin: 20}}>
                <Checkbox
                  checked={program.isActive}
                  onChange={(event) =>
                    onChange('isActive', event.target.checked)
                  }>
                  Active
                </Checkbox>
              </div>
            </Col>
          </Row>

          {/************************************************************************************************************************/}
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}}>Courses </Text>
                {program.courses?.map((course, index) => (
                  <div key={`${course.id}_${index}`}>
                    <Link to={`/courses/${course.id}/edit`}>{course.name}</Link>
                    <br />
                  </div>
                ))}
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}}>Packages </Text>
                {program.packages?.map((pkg, index) => (
                  <div key={`${pkg.id}_${index}`}>
                    <Link to={`/packages/${pkg.id}/edit`}>
                      {resolveI18nObject(pkg.name_i18n)}
                    </Link>
                    <br />
                  </div>
                ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}}>Placement test2 </Text>
                <Select
                  onSearch={(searchValue) => {
                    if (searchValue.length > 3) {
                      testsService
                        .getTestByName(searchValue)
                        .then((testsResult) => {
                          setTests(testsResult);
                        });
                    }
                  }}
                  value={`${program.test?.name}+${program.test?.id}`}
                  showSearch
                  style={{width: '100%'}}
                  placeholder="Select a Test"
                  optionFilterProp="children"
                  onChange={(selectedTestId: any) => {
                    //console.log('selectedTestId->', selectedTestId);
                    let selectedTest = tests.find(
                      (test: Test) => test.id === selectedTestId,
                    );
                    //console.log('selectedTest->', selectedTest);
                    onChange('test', selectedTest);
                  }}>
                  {tests?.map((test, index) => {
                    return (
                      <Select.Option
                        key={`${test.id}_${index}`}
                        value={test.id}>
                        {test.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </Col>
            <Col span={12}>
              <div style={{margin: 20}}>
                <Text style={{display: 'block'}} strong={true}>
                  Program flag
                </Text>
                <Input
                  value={program?.flag ?? ''}
                  onChange={(event) => onChange('flag', event.target.value)}
                />
              </div>
            </Col>
          </Row>
          {/************************************************************************************************************************/}
          <Row>
            <Col span={12}>
              <EditAssetsBlock
                assets={program.activities}
                fieldNameSingular={'Activity'}
                fieldName={'Activities'}
                onChange={(e) => {
                  onChange('activities', e);
                }}
                defaultAssetType={AssetType.VIDEO}
              />
            </Col>

            <Col span={12}>
              <EditAssetsBlock
                fieldNameSingular={'Record'}
                fieldName={'Records'}
                assets={program.records}
                onChange={(e) => {
                  onChange('records', e);
                }}
                defaultAssetType={AssetType.VIDEO}
              />
            </Col>
          </Row>
        </>
      ) : (
        <Loading />
      )}
      <Button
        onClick={async () => {
          const temp = cloneDeep(program);
          await programsService
            .updateProgram(temp)
            .then((resp: any) => {
              alert('Program edited');
              history.go(0);
            })
            .catch((err) => {
              alert('Program failed');
            });
        }}>
        Edit
      </Button>

      {isError && <Title>Program fetching error ⚠️</Title>}
    </div>
  );
};
